<template lang="pug">
    section#neuronios
        .wrapper
            h3 Neurônios
            p
                |Conectamos diversas inteligências.
                br
                |Conheça nossa equipe fixa de neurônios
            ul
                li(v-for="person, index in equipe", ref="li")
                    .img
                        img(:src="person.foto")
                    h4 {{person.nome}}
                    p {{person.cargo}}
</template>

<script>
import Scenes from '@views/Equipe/scenes.js'

export default {
    name: "section-neuronios",
    // mixins: {
    //     Scenes,
    // },
    data() {
        return {
            scenes: [],
            equipe: [
                {
                    nome: 'Thais Scaglione',
                    cargo: 'Manager of Strategic Design & Innovation',
                    foto: require('@images/equipe/Thais.jpg'),
                },
                {
                    nome: 'Isabela Pacheco',
                    cargo: 'Head of Business Process & Innovation',
                    foto: require('@images/equipe/Isabela.jpg'),
                },
                // {
                //     nome: 'Mayra Lafoz',
                //     cargo: 'Anthropologist & Social Scientist',
                //     foto: require('@images/equipe/Mayra.jpg'),
                // },
                {
                    nome: 'Henrique Buzatto',
                    cargo: 'Innovation Analyst',
                    foto: require('@images/equipe/Henrique.jpg'),
                },
                {
                    nome: 'Sandro França',
                    cargo: 'Senior Graphic Designer',
                    foto: require('@images/equipe/Sandro.jpg'),
                },
            ]
        }
    },
    mounted() {
		this.initScenes()
		setTimeout(() => {
            this.$scrollmagic.addScene(this.scenes)
        }, 1000)
    },
    methods: {
        initScenes() {
            this.scenesEquipe()
        },
        scenesEquipe() {
            //Title
			this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#neuronios',
				triggerHook: 0.6,
                reverse: false
			}).setTween("#neuronios h3", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))

            //Description
			this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#neuronios',
				triggerHook: 0.50,
                reverse: false
			}).setTween("#neuronios p", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))

			// Boxes
			this.$refs.li.forEach((item, index) => {
				this.scenes.push(this.$scrollmagic.scene({
					triggerElement: '#neuronios',
					triggerHook: 0.4,
					reverse: false
				}).setTween(`#neuronios li:nth-child(${index+1})`, {
					css: {
						animation: 'boxAnimation 1s linear forwards ' + (0.5*index+'s'),
					}
				}))
			});
		},
    }
}
</script>

<style lang="stylus" scoped src="./Neuronios.styl"></style>
<style lang="stylus">
    @keyframes boxAnimation
        100%
            opacity 1; transform translateY(0)
</style>
