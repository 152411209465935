<template lang="pug">
    section#formulario
        SvgIcon(data="@svgs/neuronio.svg").neuronio
        .wrapper
            .text
                h3
                    | Q
                    span._1100 ue
                    | r f
                    span._1100 a
                    | z
                    span._1100 e
                    | r
                    br
                    | p
                    span._1100 a
                    | rt
                    span._1100 e
                    |  da
                    |  n
                    span._1100 o
                    | ssa
                    br
                    | r
                    span._1100 e
                    | d
                    span._1100 e
                    |  n
                    span._1100 eu
                    | ral?

                p
                    |Contamos com áreas de
                    strong  Pesquisa,
                    br
                    strong Criação, Estratégia e Experiência
                    br
                    strong do Usuário.
                    |  Se você se
                    strong  identificou
                    br
                    strong com nosso propósito
                    |  e quer fazer
                    br
                    |parte do nosso time, inscreva-se
                    br
                    |no formulário ao lado ou mande
                    br
                    |seu currículo para a gente!

            .box
                form(@submit.prevent="send")
                    h4 Vem brilhar com a gente!
                    p Preencha o formulário abaixo:
                    .input(:class="{ error: form_error.nome }")
                        label(for="nome") Nome*
                        input(name="nome" v-model="form.nome")
                        span {{ form_error.nome }}
                    .input(:class="{ error: form_error.email }")
                        label(for="email") Email*
                        input(name="email" v-model="form.email")
                        span {{ form_error.email }}
                    .input(:class="{ error: form_error.telefone }")
                        label(for="telefone") Telefone*
                        input(name="telefone" v-model="form.telefone" v-mask="['(##) #####-####', '(##) ####-####']")
                        span {{ form_error.telefone }}
                    .input(:class="{ error: form_error.area }")
                        label(for="area") Área de atuação*
                        input(name="area" v-model="form.area")
                        span {{ form_error.area }}
                    .input(:class="{ error: form_error.link }")
                        label(for="link") LinkedIn ou outros links*
                        input(name="link" v-model="form.link")
                        span {{ form_error.link }}
                    .input(:class="{ error: form_error.mensagem }")
                        label(for="mensagem") Porquê quero ser um minder*
                        textarea(name="mensagem" v-model="form.mensagem" rows="12")
                        span {{ form_error.mensagem }}
                    .checkbox
                        input(type="checkbox" v-model="agreed", id="input-agreed")
                        label(for="input-agreed")
                            span Estou ciente de que forneço meus dados pessoais aos canais de comunicação da Mindservice, com a finalidade de buscar oportunidades para a minha carreira. Tais dados serão tratados nos termos da Política de Privacidade da Mindservice e da Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados”).
                    button(type="submit", v-html="buttonText", :disabled="disabled || !this.agreed")
</template>

<script>
import { mask } from 'vue-the-mask';

const FORMULARIO = {
  nome: '',
  email: '',
  telefone: '',
  area: '',
  link: '',
  mensagem: ''
}

export default {
  name: 'component-formulario',
  directives: {
    mask
  },
  data () {
    return {
      form: { ...FORMULARIO },
      agreed: false,
      form_error: {},
      buttonText: 'Enviar',
      disabled: false
    }
  },
  methods: {
    send () {
      if (!this.agreed) return

      this.buttonText = 'Enviando...'
      this.disabled = true
      this.form_error = {}

      const { email, ...form } = this.form
      const data = {
        origin: 'Trabalhe Conosco',
        client: 'Mind',
        subject: 'Contato - Trabalhe Conosco',
        email,
        requiredFields: ['nome', 'telefone', 'area', 'link', 'mensagem'],
        fields: { ...form },
        sendto: process.env.VUE_APP_MAILER_TEAM_SEND_TO,
        sendfrom: process.env.VUE_APP_MAILER_TEAM_SEND_FROM
      }

      this.$axios
        .post('send', data)
        .then(() => {
          this.buttonText = 'Enviado com sucesso!'
          this.form = { ...FORMULARIO }
        })
        .catch(error => {
          this.form_error = JSON.parse(error.response.data.errors)
          this.buttonText = 'Ocorreu um erro!'
        })
        .finally(() => {
          setTimeout(() => {
            this.disabled = false
            this.buttonText = 'Enviar'
          }, 5000)
        })
    }
  }
}
</script>

<style lang="stylus" scoped src="./TrabalheConosco.styl"></style>
