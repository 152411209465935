<template lang="pug">
	main#equipe
		Header
		Banner
		Neuronios
		TrabalheConosco
</template>

<script>
import Banner from '@sections/Equipe/Banner/Banner'
import Neuronios from '@sections/Equipe/Neuronios/Neuronios'
import Header from '@components/Header/Header'
import TrabalheConosco from '@components/TrabalheConosco/TrabalheConosco'

export default {
	name: "view-equipe",
	components: {
		Banner,
		Header,
		Neuronios,
		TrabalheConosco
	},
	metaInfo() {
		return {
			title: 'Equipe',
			meta: [
				{ name: 'description', content: 'Somos mais que um time, somos minders.' },
			],
		}
	},

}
</script>

<style lang="stylus" scoped src="./Equipe.styl"></style>
