<template lang="pug">
    section#banner
        SvgIcon(data="@svgs/equipe/explosion.svg", original).explosion
        .wrapper
            .svgs
                SvgIcon(data="@svgs/icon_01.svg", original)._svg01
                SvgIcon(data="@svgs/icon_02.svg", original)._svg02
                SvgIcon(data="@svgs/icon_03.svg", original)._svg03
            .container
                h2
                    |S
                    span._1100 o
                    |m
                    span._1100 o
                    |s m
                    span._1100 a
                    |is
                    span.br
                        br
                    |  qu
                    span._1100 e
                    |  um tim
                    span._1100 e
                    |,
                    span.br
                        br
                    |S
                    span._1100 o
                    |mos mind
                    span._1100 e
                    |rs

                p
                    |Conectamos neurônios, potencializamos
                    |sinapses e buscamos a originalidade.<br>
                    |Somos agentes da mudança e queremos
                    |proporcionar mudanças por aí.<br>
                strong Vem conhecer a gente!
            SvgIcon(data="@svgs/m.svg", original).m
</template>

<script>
export default {
    name: "section-banner",
}
</script>

<style lang="stylus" scoped src="./Banner.styl"></style>
